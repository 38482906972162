import React, { useContext, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import { Button, Dropdown, Icon, Input, SelectDates } from 'components';
import Context from './OffersContext';

dayjs.extend(AdvancedFormat);

const OffersControls = () => {
  const { statusFilter, setStatusFilter, statusOptions, searchFilter, setSearchFilter, dateFilter, setDateFilter } = useContext(Context);
  const [openCalendar, setOpenCalendar] = useState<boolean>();
  const { start, end } = dateFilter;
  const isMobile = useLayout() === 'mobile';

  const statusFilterText = useMemo(() => {
    if (statusFilter.length === statusOptions.length) return 'All';
    if (statusFilter.length === 1) {
      return (statusOptions?.find(item => item.value === statusFilter[0])?.text ||
        statusFilter[0]) as string;
    }
    return statusFilter.join(', ');
  }, [statusFilter, statusOptions]);

  const dateLabel = useMemo(() => {
    if (!start || !end) return '';
    const startDate = dayjs.unix(start);
    const endDate = dayjs.unix(end);
    const isSame = startDate.isSame(endDate, 'day');
    return isSame
      ? endDate.format('MMM Do')
      : `${startDate.format('MMM Do')} - ${endDate.format('MMM Do')}`;
  }, [start, end]);

  const onDatesSelect = ({ start, end }) => {
    const startDate = dayjs(start).startOf('d').unix();
    const endDate = dayjs(end).endOf('d').unix();
    setDateFilter({ start: startDate, end: endDate });
  };

  const getLayout = () => {
    if (isMobile) {
      return (
        <>
          <Button
            onClick={() => setOpenCalendar(v => !v)}
            simple
            className="offers-controls__filter date-btn"
          >
            <Icon name="calendar" /> <label>Date:</label>&nbsp;{dateLabel}
            <Icon name="chevron" />
          </Button>
          <Dropdown
            options={statusOptions}
            value={statusFilter}
            onChange={setStatusFilter}
            label="Status:"
            text={statusFilterText}
            buttonLike
            multiple
            optionAll
            className="offers-controls__filter offers-controls__dropdown"
          />
          {/* <Button className="offers-controls__filter" secondary>
            <Icon name="search" />
          </Button> */}
          <Button className="offers-controls__filter">
            <Icon name="plus-mini" />
          </Button>
        </>
      );
    }
    return (
      <>
        <Input
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
          icon="search"
          iconPosition="left"
          placeholder="Search"
          blurOnSubmit
          className="offers-controls__filter search-filter"
        />
        <Button
          onClick={() => setOpenCalendar(v => !v)}
          simple
          className="offers-controls__filter date-btn"
        >
          <Icon name="calendar" /> <label>Date:</label>&nbsp;{dateLabel}
          <Icon name="chevron" />
        </Button>
        <Dropdown
          options={statusOptions}
          value={statusFilter}
          onChange={setStatusFilter}
          label="Status:"
          text={statusFilterText}
          buttonLike
          multiple
          optionAll
          className="offers-controls__filter offers-controls__dropdown"
        />
        <Button className="offers-controls__filter">Add new</Button>
      </>
    );
  };

  return (
    <>
      <StyledOffersControls className="offers-controls">
        {getLayout()}
      </StyledOffersControls>
      <SelectDates
        start={start}
        end={start}
        open={openCalendar}
        onSubmit={onDatesSelect}
        onClose={() => setOpenCalendar(false)}
      />
    </>
  );
};

export default React.memo(OffersControls);

const StyledOffersControls = styled.div`
  display: flex;
  .offers-controls {
    &__filter {
      margin-left: 10px;
      display: flex;
      align-items: center;
      .input-wrapper .input-component {
        height: 32px;
        width: 300px;
      }
    }
    &__dropdown {
      max-width: 160px;
      .dropdown-options {
        width: auto;
      }
      .dropdown-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .date-btn {
    transition: ${props => props.theme.transitions.standart};
    &.simple {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0px 8px;
      border: 1px solid #000;

      label {
        font-weight: 500;
        color: ${props => props.theme.colors.grayDark};
      }

      .icon-calendar {
        margin-right: 6px;
      }
      &:hover {
        border-color: ${props => props.theme.colors.red};
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
  }
`;
